import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero2 from '../components/hero2'
import Featured from '../components/featured'
import News from '../components/home/news'
import NaturalPool from '../components/home/natural-pool'
import SupportContact from '../components/home/support-contact'
import Relax from '../components/home/relax'
import Difference from '../components/home/difference'
import Technology from '../components/home/technology'
import Robots from '../components/home/robots'
import ClimateCare from '../components/home/climate-care'
import Testimonials from '../components/home/testimonials'
import WhichModel from '../components/home/which-model'
import Reasons from '../components/home/reasons'

const IndexPage = () => {
	return (
		<Layout>
			<Meta
				title="Automatic Robotic Pool Cleaners &amp; Vacuums"
				description="Are you looking for the best robotic pool cleaner to automatically vacuum your pool? Speak to the team at Maytronics about a Dolphin electric pool cleaner today."
				keywords={[`maytronics`, `maytronics new south africa`, `home`]}
				lang="en-ZA"
			>
				<link
					rel="alternate"
					href="https://www.maytronics.com.es/"
					hrefLang="es-ES"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.it/"
					hrefLang="it-IT"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.co.il/"
					hrefLang="he-IL"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com.de/"
					hrefLang="de-DE"
				/>
				<link
					rel="alternate"
					href="https://www.robot-dolphin.fr/"
					hrefLang="fr-FR"
				/>
				<link
					rel="alternate"
					href="https://maytronicsus.com/"
					hrefLang="en-US"
				/>
				<link
					rel="alternate"
					href="https://maytronicsus.com/"
					hrefLang="en-CA"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com.de/"
					hrefLang="de-AT"
				/>
				<link
					rel="alternate"
					href="https://maytronics.com.au/"
					hrefLang="en-AU"
				/>
				<link
					rel="alternate"
					href="https://maytronics.com.au/en-NZ/"
					hrefLang="en-NZ"
				/>
				<link
					rel="alternate"
					href="https://www.maytronics.com/"
					hrefLang="x-default"
				/>
			</Meta>
			<Hero2 locale="en-ZA" />
			<Relax locale="en-ZA" />
			<Difference locale="en-ZA" />
			<Technology locale="en-ZA" />
			<Featured locale="en-ZA" />
			<Robots locale="en-ZA" />
			<ClimateCare locale="en-ZA" />
			<Testimonials locale="en-ZA" />
			<WhichModel locale="en-ZA" />
			{/* <NaturalPool locale="en-ZA" /> */}
			<Reasons locale="en-ZA" />
			<SupportContact locale="en-ZA" />
			<News locale="en-ZA" />
		</Layout>
	)
}

export default IndexPage
